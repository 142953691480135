import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { FormData, FormErrors } from '../types/booking';
import { validateForm } from '../utils/validation';
import BookingForm from './booking/BookingForm';
import CalendlyEmbed from './booking/CalendlyEmbed';
import ErrorMessage from './booking/ErrorMessage';

export default function BookingModal() {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: ''
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [step, setStep] = useState(1);
  const [calendlyError, setCalendlyError] = useState(false);

  useEffect(() => {
    // Load Calendly widget CSS and JS
    const link = document.createElement('link');
    link.href = 'https://assets.calendly.com/assets/external/widget.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up
      document.head.removeChild(link);
      const existingScript = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]');
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name as keyof FormErrors]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  const saveContact = async () => {
    try {
      await supabase.from('contacts').insert({
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        phone: formData.phone,
        company: formData.company,
        status: 'pending'
      });
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validateForm(formData);
    if (!validation.isValid) {
      setErrors(validation.errors);
      return;
    }

    await saveContact();
    setStep(2);
  };

  return (
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 bg-black/40 backdrop-blur-sm" />
      <Dialog.Content 
        className="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] 
                 translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white shadow-xl 
                 overflow-y-auto"
      >
        <Dialog.Title className="text-2xl font-bold mb-6 pr-8 pt-6 px-6">
          Planifier une consultation gratuite
        </Dialog.Title>
        
        <Dialog.Close className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100">
          <X className="h-5 w-5" />
        </Dialog.Close>

        <div className="px-6 pb-6">
          {step === 1 ? (
            <BookingForm
              formData={formData}
              errors={errors}
              onSubmit={handleSubmit}
              onChange={handleInputChange}
            />
          ) : calendlyError ? (
            <ErrorMessage />
          ) : (
            <CalendlyEmbed
              formData={formData}
              onError={() => setCalendlyError(true)}
            />
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}