import React, { useEffect } from 'react';
import type { FormData } from '../../types/booking';

interface Props {
  formData: FormData;
  onError: () => void;
}

declare global {
  interface Window {
    Calendly: any;
  }
}

export default function CalendlyEmbed({ formData, onError }: Props) {
  useEffect(() => {
    // Initialize Calendly popup
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/exelisia/consultation-gratuite?hide_event_type_details=1&hide_gdpr_banner=1',
        prefill: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          customAnswers: {
            a1: formData.company // Only send company name
          }
        }
      });
    } else {
      onError();
    }
  }, [formData, onError]);

  return null;
}