import React from 'react';
import { AlertCircle } from 'lucide-react';

export default function ErrorMessage() {
  return (
    <div className="text-center py-8">
      <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
      <h3 className="text-lg font-semibold text-gray-900 mb-2">
        Impossible de charger le calendrier
      </h3>
      <p className="text-gray-600">
        Une erreur est survenue lors du chargement du calendrier. Veuillez réessayer ultérieurement ou nous contacter directement.
      </p>
      <a
        href="mailto:contact@exelisia.com"
        className="mt-4 inline-block text-primary hover:underline"
      >
        contact@exelisia.com
      </a>
    </div>
  );
}