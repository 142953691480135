import React, { useEffect } from 'react';
import '../styles/chatbot.css';

declare global {
  interface Window {
    embeddedChatbotConfig?: {
      chatbotId: string;
      domain: string;
    };
  }
}

export default function ChatbotEmbed() {
  useEffect(() => {
    // Set chatbot config
    window.embeddedChatbotConfig = {
      chatbotId: "fMlMuKCKkjv3dncTtIWGV",
      domain: "www.chatbase.co"
    };

    // Create and load the script
    const script = document.createElement('script');
    script.src = "https://www.chatbase.co/embed.min.js";
    script.setAttribute('chatbotId', "fMlMuKCKkjv3dncTtIWGV");
    script.setAttribute('domain', "www.chatbase.co");
    script.defer = true;
    
    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
      delete window.embeddedChatbotConfig;
    };
  }, []);

  return null;
}