import type { FormData, FormErrors } from '../types/booking';

export const validateForm = (data: FormData): { isValid: boolean; errors: FormErrors } => {
  const errors: FormErrors = {};
  
  if (!data.firstName.trim()) errors.firstName = 'Le prénom est requis';
  if (!data.lastName.trim()) errors.lastName = 'Le nom est requis';
  if (!data.company.trim()) errors.company = 'La société est requise';
  
  if (!data.email.trim()) {
    errors.email = "L'email est requis";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = "L'email n'est pas valide";
  }
  
  if (!data.phone.trim()) {
    errors.phone = 'Le téléphone est requis';
  } else if (!/^(\+33|0)[1-9](\d{2}){4}$/.test(data.phone.replace(/\s/g, ''))) {
    errors.phone = 'Le numéro de téléphone doit être au format français';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};