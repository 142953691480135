import React, { useState, useRef } from 'react';
import { Play, Pause } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import BookingModal from './BookingModal';

export default function Hero() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = async () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error("Playback failed:", error);
        }
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  return (
    <div className="pt-32 pb-16 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:grid lg:grid-cols-12 lg:gap-8">
          {/* Video Section - Appears first on mobile */}
          <div className="order-1 lg:order-2 mb-12 lg:mb-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-2xl shadow-xl overflow-hidden group" style={{ maxWidth: '800px' }}>
              <video
                ref={videoRef}
                className="w-full h-full cursor-pointer"
                playsInline
                preload="auto"
                onClick={togglePlay}
                onEnded={handleVideoEnd}
                src="https://res.cloudinary.com/dlx1bgbew/video/upload/v1732394495/video-exelisia.mp4"
              >
                Votre navigateur ne supporte pas la lecture de vidéos.
              </video>
              
              {/* Play/Pause Button Overlay with Pulse Animation */}
              <button
                onClick={togglePlay}
                className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                           w-20 h-20 flex items-center justify-center rounded-full 
                           bg-black/50 text-white transition-all duration-300
                           ${isPlaying ? 'opacity-0 group-hover:opacity-100' : 'opacity-100'}
                           before:content-[''] before:absolute before:inset-0 
                           before:rounded-full before:bg-white/30 
                           before:animate-ping before:opacity-75`}
                aria-label={isPlaying ? 'Pause' : 'Play'}
              >
                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-primary to-secondary opacity-80"></div>
                {isPlaying ? (
                  <Pause className="h-10 w-10 relative z-10" />
                ) : (
                  <Play className="h-10 w-10 ml-1 relative z-10 animate-bounce" />
                )}
              </button>

              {/* "Cliquez ici" Text */}
              {!isPlaying && (
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 
                               bg-black/70 px-4 py-2 rounded-full text-white text-sm
                               animate-pulse">
                  Cliquez ici
                </div>
              )}
            </div>
          </div>

          {/* Text Section - Appears second on mobile */}
          <div className="order-2 lg:order-1 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="block text-5xl tracking-tight font-extrabold sm:text-6xl xl:text-7xl">
                <span className="gradient-text">Réduisez vos coûts</span> avec l'IA
              </span>
            </h1>
            <p className="mt-6 text-xl text-gray-500 leading-relaxed">
              Révolutionnez vos services professionnels grâce à nos solutions d'automatisation alimentées par l'intelligence artificielle. Conçu spécifiquement pour les professions comptables et juridiques.
            </p>
            <div className="mt-10 flex justify-center lg:justify-start">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="nav-btn text-base">
                    <span>Découvrez vos gains potentiels</span>
                  </button>
                </Dialog.Trigger>
                <BookingModal />
              </Dialog.Root>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}